<template>
  <v-card>
    <v-card-title>Tweets from Getachew Reda</v-card-title>
    <v-carousel v-if="tweets.length > 0" cycle hide-delimiter>
      <v-card v-for="tweet in tweets" :key="tweet.id">
        <v-card-text>
          <v-list-item-avatar>
            <v-img :src="tweet.user.profile_image_url_https" alt="Avatar">
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ tweet.user.name }}</v-list-item-title>
            <v-list-item-subtitle v-html="tweet.text"></v-list-item-subtitle>
          </v-list-item-content>
        </v-card-text>
      </v-card>
      <v-card dark flat v-if="tweets.length === 0">
        <v-card-text>No tweets found.</v-card-text>
      </v-card>
    </v-carousel>
    <v-btn icon small v-if="tweets.length > 1" @click.prevent="prevTweet">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn icon small v-if="tweets.length > 1" @click.prevent="nextTweet">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      tweets: [],
      currentTweetIndex: 0,
    };
  },
  created() {
    // Replace with your actual API credentials
    const API_KEY = 'MEVWmgLYBXzKEWzaoy9FrFNzl';
    const API_SECRET = 'ASgxOPpNIG98BbZdcIWpOQevppLuLrmNxTjDwQJETRQU6CGs6Q';
    const ACCESS_TOKEN = '1363759284488704001-lo8s3xKd5Cd2DYezVyExeAG9cbTC2F';
    const ACCESS_TOKEN_SECRET = 'bNnbYpDV7X5mhVstbf0NyyoMyxY8fgmpMB7GmYOMGmytC';

    // Construct the search query (replace with desired search terms)
    const searchQuery = 'from:@reda_getachew';

    // Encode credentials (Base64)
    const encodedCredentials = btoa(`${API_KEY}:${API_SECRET}`);

    // Prepare request headers
    const headers = {
      Authorization: `Basic ${encodedCredentials}`,
    };

    // Make the search request using axios
    axios
      .get('https://api.twitter.com/1.1/search/tweets.json', {
        params: {
          q: searchQuery,
          count: 10, // Adjust as needed
        },
        headers,
      })
      .then(response => {
        this.tweets = response.data.statuses;
      })
      .catch(error => {
        console.error('Error fetching tweets:', error);
      });
  },
  methods: {
    prevTweet() {
      this.currentTweetIndex = (this.currentTweetIndex - 1 + this.tweets.length) % this.tweets.length;
    },
    nextTweet() {
      this.currentTweetIndex = (this.currentTweetIndex + 1) % this.tweets.length;
    },
  },
};
</script>
<style scoped>
  .v-card__text {
    display: flex;
    align-items: center;
  }
</style>
