<template>
    <v-row  v-if="loading" class="justify-center mt-15">
      <v-progress-circular 
        indeterminate
        :size="50"
        :width="1"
        color="blue-darken-3">
      </v-progress-circular>
    </v-row>
    <v-row v-else class="justify-center">
      <v-col cols=12 sm=8>
        <v-carousel  height="400" hide-delimiters cycle interval="3000"> 
          <v-carousel-item v-for="(item, index) in slidesResponse" :key="index">
            <v-row class="fill-height ma-0">
              <v-col cols="12" class="p-0">
                <v-img :src="`${$root.globalImgUrl}/${item.image}`" class="fill-space"></v-img>
                <div class="carousel-overlay">
                  <div class="animated-text">
                    <h2 class="title">{{ trans(item.id, item, 'name') }}</h2>
                    <!-- <v-btn color="primary" size="small" class="view-more">View More</v-btn> -->
                    <h3 class="subtitle"></h3>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols=12 sm=3 class="mt-3" v-if="!mobile">
        <v-sheet
          style="padding: 40px;"
          class="d-flex align-center justify-center flex-wrap mx-auto px-4"
          elevation="1"
          rounded
          height="400"
          v-if="moto"
        >
        <div>
          <h2 class="text-h4 font-weight-black text-blue-darken-3">{{trans(moto.id, moto, 'moto')}}</h2><br>
           <h2 class="text-h6 font-weight-black text-center text-blue-darken-1">" {{trans(moto.id, moto, 'description')}} "</h2>
           <!-- <h3 class="text-h6 font-weight-black text-center ">President Getachew reda</h3> -->
          </div>
        </v-sheet>
      </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
import { useUserStore } from "@/store/auth";

export default {
  data() {
    const auth = useUserStore()
    return {
      auth,
      langSearch:"",
      slidesResponse: [],
      loading: true,
      currentIndex: 0,
      mobile: null,
      windowWidth: null,
      moto:null,
    };
  },
  mounted () {   
      this.getSlides();
      this.getMoto();
        window.addEventListener("resize",this.checkscreen);
    },
  methods: {
    getSlides(){
      axios
      .get('/slides')
        .then(response => {
          this.slidesResponse = response.data.mydata
          this.loading = !this.loading
        })
        .catch(error => {
        });
    },
    async getMoto(){
      await axios.get('/motos')
        .then(response => {
          this.moto = response.data.mydata[0]
        })
        .catch(error => {
        });
    },
    trans(id, str, col){
        if(this.auth.lang==="En")
          this.langSearch="EN"
        else if(this.auth.lang==="ትግ")
          this.langSearch="ti"
        else if(this.auth.lang==="ኣማ")
          this.langSearch="am"
      const translation = str.translations.find(translation => translation.locale === this.langSearch && translation.column_name === col);
      if(col == 'name')
        return translation ? translation.value : str.name;
      else if(col == 'moto')
        return translation ? translation.value : str.moto;
      else if(col == 'description')
        return translation ? translation.value : str.description;
    },
    checkscreen(){
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1060) {
        this.mobile = true;
        return;
      }
      else {
        this.mobile = false;
      } 
    },
  },
}
</script>

<style>
.carousel-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px; /* Adjust as needed */
  color: white;
  z-index: 1;
}

.animated-text {
  animation: slideInFromBottom 1s ease-out;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.title {
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: bold; /* Make the text bold */
  color: white;
  background-color: rgba(0, 0, 0, 0.5); /* Add a background color with 50% opacity */
  padding: 8px; /* Add some padding for better readability */
}

.subtitle {
  font-size: 18px;
  margin-bottom: 16px;
}

.view-more {
  margin-top: 16px;
}

.fill-space {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>