import {createI18n} from 'vue-i18n';
import En from './en.json';
import tg from './tg.json';
import am from './am.json';
const i18n=createI18n({
	locale:'En',
	messages:{
		En:En,
		ትግ:tg,
		ኣማ:am,
	},
})
export default i18n