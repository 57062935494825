<template>
  <v-app>
    <NavbarWeb/>
    <v-main>
      <router-view/>
    </v-main>
    <v-row>
      <v-col cols="12">
        <v-footer color="" class="justify-center bg-blue-darken-3" dark>
          <v-row class="mx-7">
            <v-col cols="12" md="3"
            >
              <span class="ml-10 "><h3>  {{$t('pressandmedia')}}</h3></span>
              <v-list  class="bg-blue-darken-3">
                <v-list-item link
                  prepend-icon="mdi-newspaper"
                  transparent="1"
                  v-for="(category, index) in categoriesResponse"
                  :key="index"
                  :href="`/#/posts/${category.slug}`">{{trans(category.id, category, 'name')}}
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3"
            >
              <span class="ml-10 "><h3> {{$t('resource')}}</h3></span>
              <v-list  class="bg-blue-darken-3"                
              >
                <v-list-item transparent="1"
                v-for="(resourceType, index) in resourceTypes"
                  prepend-icon="mdi-hand-pointing-right"
                  :to="`/resources/${resourceType.slug}`"
                >{{trans(resourceType.id, resourceType, 'name')}}
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3"
            >
              <span class="ml-10 "><h3> {{$t('browsebySector')}}</h3></span>              
              <v-list  class="bg-blue-darken-3">
                <v-list-item transparent="1"
                  v-for="link in linksResponse"                
                  prepend-icon="mdi-link"
                >
                  <a :href="`${link.url}`" target="_blank" 
                    style="text-decoration:none; color:white">{{trans(link.id, link, 'title')}}
                  </a>                  
                </v-list-item>
                <v-list-item transparent="1">
                  <v-btn text color="white" to="/important-links" size="small">{{$t('visitalllinks')}}</v-btn>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3"
            >
              <span class="ml-10 "><h3> {{$t('contact')}}</h3></span>
              <v-list  class="bg-blue-darken-3">
                <v-list-item transparent="1"
                  prepend-icon="mdi-phone" v-if="contactInfoResponse"
                >
                  {{contactInfoResponse.phone1}} <br> {{contactInfoResponse.phone2}}
                </v-list-item>
                <v-list-item transparent="1"
                  prepend-icon="mdi-email" v-if="contactInfoResponse"
                >
                  {{contactInfoResponse.email}}
                </v-list-item>                
                <v-list-item transparent="1"
                  prepend-icon="mdi-message"
                >
                  <a href="/#/contact-us" style="text-decoration:none; color:white">{{$t('Sendusyourmessage')}}</a>
                </v-list-item>
                <v-list-item transparent="1"
                  prepend-icon="mdi-map-marker"
                >
                  {{$t('MekelleTigrayEthiopia')}}
                </v-list-item>
              </v-list>
              <v-list  class="bg-blue-darken-3">
                <v-list-item>
                  <span v-for="socialMedia in socialMediasResponse"
                    :key="socialMedia.id" >
                    <v-tooltip 
                      v-if="socialMedia.status"
                      :text="socialMedia.title" location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn :icon="`mdi-${socialMedia.icon}`" :href="`${socialMedia.url}`" color="transparent" density="comfortable" elevation="1" v-bind="props"></v-btn>
                      </template>
                    </v-tooltip>
                    &nbsp; &nbsp; 
                  </span> 
                </v-list-item>
              </v-list>              
            </v-col>
              <!-- Scroll to Top button -->
            <v-btn v-if="showScrollToTop" fab dark bottom right @click="scrollToTop" color="blue lighten-1" class="scroll-to-top" icon>
              <v-icon>mdi-chevron-double-up</v-icon>
            </v-btn> 
            <!-- <v-btn   @click="scrollToTop" color="blue lighten-1" class="floating-sm" icon>
              <v-icon>mdi-home</v-icon>
            </v-btn> -->
          </v-row>
        </v-footer>
        <v-footer color="" class="justify-center bg-grey-darken-4" dark>
          <span>&copy; {{ new Date().getFullYear() }} {{$t('top')}}</span>
          <!-- This is {{ trans('home') }} -->
          <span v-if="auth.lang==='En'">&nbsp; &nbsp; | &nbsp; &nbsp; Developed by: <a href="https://www.pilasatech.net" target="_blank" style="text-decoration:none; color: #b3e5fc"><b>Pilasa Technologies</b></a></span>
          <span v-if="auth.lang==='ትግ'">&nbsp; &nbsp; | &nbsp; &nbsp; ብ <a href="https://www.pilasatech.net" target="_blank" style="text-decoration:none; color: #b3e5fc"><b>ፒላሳ ቴክኖሎጅስ</b></a> ዝማዕበለ</span>
          <span v-if="auth.lang==='ኣማ'">&nbsp; &nbsp; | &nbsp; &nbsp; በ <a href="https://www.pilasatech.net" target="_blank" style="text-decoration:none; color: #b3e5fc"><b>ፒላሳ ቴክኖሎጅስ</b></a> የበለፀገ</span>
        </v-footer>
      </v-col>
    </v-row>    
  </v-app>
</template>

<script>
import NavbarWeb from '@/components/NavbarWeb.vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import { useUserStore } from "@/store/auth";
export default defineComponent({
  name: 'App',
  components: {
    NavbarWeb,
  },
  data: () => ({
    //
    show: false,
    mobile: null,
    windowWidth: null,
    showScrollToTop: false,
    resourceTypes: [],
    categoriesResponse: [],
    socialMediasResponse: [],
    linksResponse: [],
      globalImgUrl: 'https://www.tigraypo.gov.et/portal/public/',
    auth:useUserStore(),
    langSearch:"",
    contactInfoResponse:null,
  }),
  computed: { 
    currentRouteName(){
      return this.$route.name;
    }
  },
  mounted () {      
    window.addEventListener("resize",this.checkscreen);
    this.getResourceTypes();
    this.getCategories();
    this.getSocialMedias();
    this.getLinks();
    this.getContactInfo();
      this.countUser();
    
    // Add a scroll event listener when the component is mounted
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
     // Remove the scroll event listener when the component is destroyed
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    trans(id, str, col){
        if(this.auth.lang==="En")
          this.langSearch="EN"
        else if(this.auth.lang==="ትግ")
          this.langSearch="ti"
        else if(this.auth.lang==="ኣማ")
          this.langSearch="am"
      const translation = str.translations.find(translation => translation.locale === this.langSearch && translation.column_name === col);
      if(col == 'name')
        return translation ? translation.value : str.name;
      else if(col == 'title')
        return translation ? translation.value : str.title;
      else if(col == 'description')
        return translation ? translation.value : str.description;
    },
    checkscreen(){
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1060) {
          this.mobile = true;
          return;
        }
        else {
          this.mobile = false;
        } 
      },
    handleScroll() {
      // Check if the user has scrolled down a certain amount
      this.showScrollToTop = window.scrollY > 100;
    },
    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async getResourceTypes(){
      await axios.get('/resource-types')
        .then(response => {
          this.resourceTypes = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getCategories(){
      await axios.get('/categories')
        .then(response => {
          this.categoriesResponse = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
      },
    async getSocialMedias(){
      await axios.get('/social-medias')
        .then(response => {
          this.socialMediasResponse = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getLinks(){
      await axios.get('/links')
        .then(response => {
          this.linksResponse = response.data.mydata.slice(0, 5)
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getContactInfo(){
      await axios.get('/contact-settings')
        .then(response => {
          this.contactInfoResponse = response.data.mydata[0]
        })
        .catch(error => {
          console.log(error)
        });
    },
    async countUser(){
      await axios.get('/visitors')
      .then(response => {
      })
      .catch(error => {
      });
    },
  }
})
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
.floating-sm {
  position: fixed;
  bottom: 200px;
  right: 6px;
}
</style>