import { defineStore } from 'pinia' 
import axios from 'axios'

export const useUserStore = defineStore('auth', {
  state: () => {
    return {
      lang:'En',
      langCode:0,
    }
  },
  persist: true
})
