<template>
    <v-app-bar
        app
        dark
        :src="require('@/assets/tigray-logo.png')"
        prominent
        elevation="0"
        height="50">&nbsp&nbsp&nbsp&nbsp
        <span v-if="!mobile" class="text-button">{{$t('top')}}</span>
        <v-spacer ></v-spacer>
        <!-- ${ethiopianDate.year}, Month ${ethiopianDate.month}, Day ${ethiopianDate.day} -->
      <v-list-item link to="/calendars">{{$t('upcomingevents')}}</v-list-item>
      <v-menu
        open-on-hover
        v-if="!mobile"
        :open-on-hover="true"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            color="menubg ? 'white' : 'blue'"
            v-bind="props"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important;"
          >
            {{$t('interactives')}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item link  to="/request-appointment"><v-icon>mdi-chevron-right </v-icon> {{$t('appointment')}}</v-list-item>
          <v-list-item link  to="/electronic-suggestion-box"><v-icon>mdi-chevron-right </v-icon> {{$t('suggestion')}}</v-list-item>
          <v-list-item link  to="/complaint"><v-icon>mdi-chevron-right </v-icon> {{$t('Complaint')}}</v-list-item>
          <v-list-item link  to="/polls"><v-icon>mdi-chevron-right </v-icon> {{$t('polls')}} </v-list-item>
        </v-list>
      </v-menu>
      <v-list-item link to="/faqs">{{$t('faq')}}</v-list-item>

     <v-tooltip
        v-model="showLang"
        location="bottom"
      >
        <template v-slot:activator="{ props }">
          <span v-bind="props"></span>           
        </template>
        <span>{{ chngLan }} </span>
      </v-tooltip>
      <v-menu
        transition="scale-transition"
        open-on-hover 
        :open-on-hover="true"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            color="light-blue-darken-1"
            v-bind="props"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important;"
          >
            {{auth.lang}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="auth.lang!='En'" link @click="changeLang(0)">En
          </v-list-item>
          <v-list-item v-if="auth.lang!='ትግ'" link @click="changeLang(1)">ትግ
          </v-list-item>
          <v-list-item v-if="auth.lang!='ኣማ'" link @click="changeLang(2)">ኣማ
          </v-list-item>
        </v-list>
      </v-menu>
        <span>{{timeET}}</span>
      <!-- <button @click="changeLang(0)" class="language-button">
        <img v-if="auth.lang!='En'" src="/en.jpeg" height="15" width="25" alt="English" class="flag-icon">&nbsp
      </button>
      <button @click="changeLang(1)" class="language-button">
        <img v-if="auth.lang!='ትግ'" src="/tg.png" height="15" width="25" alt="ትግርኛ" class="flag-icon">&nbsp
      </button>
      <button @click="changeLang(2)" class="language-button pr-10">
        <img v-if="auth.lang!='ኣማ'" src="/am.png" height="15" width="25" alt="አማርኛ" class="flag-icon">&nbsp
      </button> -->
    </v-app-bar>  
    <v-app-bar app color="menubg ? 'light-blue-darken-1' : ''"  height="60" class="bg-blue-darken-3"> <br>
      <v-icon class="ml-4" v-if="mobile" @click="drawerOpen = !drawerOpen">mdi-menu</v-icon>
      <br><br>
      <v-img
        :src="require('@/assets/tigray-logo.png')"
        class="ml-3"
        :alt="$t('top')"
        to="/"
      ></v-img>
        <!-- &nbsp&nbsp&nbsp&nbsp<span v-if="mobile" class="text-button">{{$t('top')}}</span> -->
        <span class="text-subtitle-1" v-if="auth.lang==='En'">Office of the <br v-if="!mobile">President of Tigray</span>
        <span class="text-subtitle-1" v-if="auth.lang==='ትግ'">ትግራይ ቤት ፅሕፈት ፕረዚደንት</span>
        <span class="text-subtitle-1" v-if="auth.lang==='ኣማ'">የትግራይ ፕሬዝዳንት ጽ/ቤት</span>
      <!-- <v-btn v-if="mobile" v-bind="props" to="/" >{{$t('top')}}</v-btn> -->
      <v-toolbar-title style="margin-left:0px;">
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-list-item v-if="!mobile" link to="/">
        <v-icon> mdi-home </v-icon> {{$t('home')}}
      </v-list-item>
      <v-menu
        open-on-hover
        v-if="!mobile"
        :open-on-hover="true"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            color="menubg ? 'white' : 'blue'"
            v-bind="props"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important;"
          >
            {{$t('about')}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item link to="/about"><v-icon>mdi-chevron-right</v-icon> {{$t('Theoffice')}} </v-list-item>
          <v-list-item link to="/thepresident"><v-icon>mdi-chevron-right</v-icon> {{$t('President')}} </v-list-item>
          <v-list-item link to="/about/organogram"><v-icon>mdi-chevron-right</v-icon> {{$t('Organizationalstructure')}} </v-list-item>
          <v-list-item link to="/about/teams"><v-icon>mdi-chevron-right</v-icon> {{$t('Managementstructure')}} </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        open-on-hover
        v-if="!mobile"
        :open-on-hover="true"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            color="menubg ? 'white' : 'blue'"
            v-bind="props"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important;"
          >
            {{$t('pressandmedia')}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item   
            v-for="(category, index) in categoriesResponse"
            :key="index"
            :to="`/posts/${category.slug}`"><v-icon>mdi-chevron-right </v-icon> {{ trans(category.id, category, 'name') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list-item v-if="!mobile" link to="/initiatives">{{$t('initiatives')}}</v-list-item>
      <v-list-item v-if="!mobile" link to="/tigray">{{$t('tigray')}}</v-list-item>
      <v-menu
        open-on-hover
        v-if="!mobile"
        :open-on-hover="true"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            color="menubg ? 'white' : 'blue'"
            v-bind="props"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important;"
          >
            {{$t('resource')}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item transparent="1"
            v-for="(resourceType, index) in resourceTypes"
            :to="`/resources/${resourceType.slug}`"
          > <v-icon>mdi-chevron-right </v-icon> {{ trans(resourceType.id, resourceType, 'name') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list-item v-if="!mobile" link to="/gallery">{{$t('gallery')}}</v-list-item>
      <v-list-item v-if="!mobile" link to="/important-links">{{$t('browsebySector')}}</v-list-item>
      <v-list-item v-if="!mobile" link to="/contact-us">{{$t('contact')}}</v-list-item>
      <!-- Button to Open Search Overlay -->
      <v-btn @click="openSearchOverlay" class="ml-2" elevation="1" icon="mdi-card-search" color="menubg ? 'white' : 'blue'" ></v-btn>
      <v-dialog v-model="searchOverlay"  max-width="600px"> <!-- persistent -->
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="query"
              label="Search any and press enter"
              ref="searchField"
              clearable
              append-inner-icon="mdi-card-search"
              variant="solo"
            ></v-text-field>
          </v-card-title>        
          <v-card-actions>
            <v-btn @click="closeSearchOverlay" :to="`/search/${query}`" color="primary">Search</v-btn>
            <v-btn @click="searchOverlay=!searchOverlay" text>Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay :value="searchOverlay" @click="closeSearchOverlay"></v-overlay>
    </v-app-bar>
    <v-navigation-drawer v-model="drawerOpen" app v-if="mobile">
      <v-list-item  link to="/">
        {{$t('home')}}
      </v-list-item>
      <v-menu
        transition="scale-transition"        
      >
        <template v-slot:activator="{ props }">
          <v-btn
            block
            variant="text"            
            v-bind="props"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important; justify-content: flex-start"
          >
            {{$t('about')}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item link to="/about"><v-icon>mdi-chevron-right</v-icon> {{$t('Theoffice')}} </v-list-item>
          <v-list-item link to="/thepresident"><v-icon>mdi-chevron-right</v-icon> {{$t('President')}} </v-list-item>
          <v-list-item link to="/about/organogram"><v-icon>mdi-chevron-right</v-icon> {{$t('Organizationalstructure')}} </v-list-item>
          <v-list-item link to="/about/teams"><v-icon>mdi-chevron-right</v-icon> {{$t('Managementstructure')}} </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        transition="scale-transition"        
      >
        <template v-slot:activator="{ props }">
          <v-btn
            block
            variant="text"
            v-bind="props"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important; justify-content: flex-start"
          >
            <span style="text-align:left;">{{$t('pressandmedia')}}</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link  
            v-for="(category, index) in categoriesResponse"
            :key="index"
            :to="`/posts/${category.slug}`">{{ trans(category.id, category, 'name') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list-item link to="/initiatives">{{$t('initiatives')}}</v-list-item>
      <v-list-item link to="/tigray">{{$t('tigray')}}</v-list-item>
      <v-menu
        transition="scale-transition"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            block
            variant="text"
            v-bind="props"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important; justify-content: flex-start"
          >
            {{$t('resource')}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item transparent="1"
            v-for="(resourceType, index) in resourceTypes"
            :to="`/resources/${resourceType.slug}`"
          > <v-icon>mdi-chevron-right </v-icon> {{ trans(resourceType.id, resourceType, 'name') }}
          </v-list-item>
        </v-list>
      </v-menu>
        <v-list-item link to="/gallery">{{$t('gallery')}}</v-list-item>
        <v-list-item v-if="!mobile" link to="/important-links">{{$t('browsebySector')}}</v-list-item>
        <v-menu
          transition="scale-transition"
          :open-on-hover="true"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              block
              variant="text"
              v-bind="props"
              append-icon="mdi-chevron-down"
              style="text-transform:none !important; justify-content: flex-start"
            >
              {{$t('interactives')}}
            </v-btn>
          </template>
          <v-list>
            <v-list-item link  to="/request-appointment"><v-icon>mdi-chevron-right </v-icon> {{$t('appointment')}}</v-list-item>
            <v-list-item link  to="/electronic-suggestion-box"><v-icon>mdi-chevron-right </v-icon> {{$t('suggestion')}}</v-list-item>
            <v-list-item link  to="/complaint"><v-icon>mdi-chevron-right </v-icon> {{$t('Complaint')}}</v-list-item>
            <v-list-item link  to="/polls"><v-icon>mdi-chevron-right </v-icon> {{$t('polls')}} </v-list-item>

          </v-list>
        </v-menu>
        <v-list-item link to="/contact-us">{{$t('contact')}}</v-list-item> 
    </v-navigation-drawer>
</template>

 <script>
 import axios from 'axios';
import { useRouter } from 'vue-router';
import { useUserStore } from "@/store/auth";
import calendar from "@/views/calendars/calendar_notification.vue";
import { i18n } from '@/main';
import {convertToGC, toEthiopianDateString, toEthiopianMonthString, toEthiopianDayString} from './index.js'

export default {
  name: 'NavbarWeb',
  props: {
    keyword: String,
  },
  components: {
    calendar,
  },
  data () {
    const auth = useUserStore()
    return {
      searchOverlay: false,
      searchQuery: '',    
      menubg: false,
      mobile: null,
      windowWidth: null,
      drawerOpen: false,
      rightDrawerOpen: false,
      categoriesResponse: [],
      resourceTypes: [],
      showLang: true,
      chngLan: 'Change Language',
      i: 0,
      chngLanHint: ['ቋንቋ ይምረፁ', 'ቋንቋ ይምረጡ', 'Change Language'],
      intervalId: null,
      langs: ['En','ትግ', 'ኣማ' ],
      fullscreen: false,  // Whether the app is currently in fullscreen mode
      router: useRouter(),
      auth,
      query: this.keyword,
      langSearch:"",
      timeET:'',
    }
  },
  mounted () {          
    window.addEventListener("resize",this.checkscreen);
    this.checkscreen();
    this.getCategories();
    this.getResourceTypes();
    this.changeLang(3);
    this.startLoop();
    this.timeET=toEthiopianDateString();

  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  methods: {
    search() {
      this.router.push("/search/"+this.query); 
    },
    startLoop() {
        // Set up a loop with a time interval (e.g., every 2 seconds in this example)
        this.intervalId = setInterval(() => {          
          // Call a function or perform a task in the loop
          this.showLang = !this.showLang;
          if(this.showLang)
            this.chngLan = this.chngLanHint[this.i];
          if(this.i == 0)
            this.i = 1;
          else if (this.i == 1)
            this.i = 2;
          else if(this.i == 2)
            this.i = 0;
          // if (this.i == 2) { this.i = 0; }
        }, 8000); // Adjust the interval time as needed

    },
    changeLang(language){
        if(language===0){
          this.auth.lang="En"
        }
        if(language===1){
          this.auth.lang="ትግ"
        }
        if(language===2){
          this.auth.lang="ኣማ"
        }
        if (this.auth.lang==null) {
         this.auth.lang='En' 
         this.auth.langCode=0 
        }

         i18n.global.locale = this.auth.lang;
      },
    showTooltip(){
      // while(true){
        'there';
        setTimeout(() => {
          // After the timeout, set sent to false
          this.showLang = !this.showLang;
          goto: there;
        }, 2000);
      // }
    },
    trans(id, str, col){
        if(this.auth.lang==="En")
          this.langSearch="EN"
        else if(this.auth.lang==="ትግ")
          this.langSearch="ti"
        else if(this.auth.lang==="ኣማ")
          this.langSearch="am"
      const translation = str.translations.find(translation => translation.locale === this.langSearch && translation.column_name === col);
      if(col == 'name')
        return translation ? translation.value : str.name;
    },
    openSearchOverlay() {
      this.searchOverlay = true;
      // Check if the ref is available before calling focus()
      if (this.$refs.searchField) {
        this.$refs.searchField.focus();
      }
    },
    closeSearchOverlay() {
      this.searchOverlay = false;
      // Optionally, perform search logic with this.searchQuery
    },
    checkscreen(){
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1060) {
        this.mobile = true;
        return;
      }
      else {
        this.mobile = false;
      } 
    },
    async getCategories(){
      await axios.get('/categories')
        .then(response => {
          this.categoriesResponse = response.data.mydata
          // this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getResourceTypes(){
      await axios.get('/resource-types')
        .then(response => {
          this.resourceTypes = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
    },
    toggleFullScreen() {
      // If the document is not currently in fullscreen mode, request fullscreen
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      }
      // Otherwise, exit fullscreen mode
      else {
        document.exitFullscreen();
      }
    },

    exitFullScreen() {
      // Exit fullscreen mode
      document.exitFullscreen();
    },
    updateFullScreenState() {
      // Update the fullscreen state based on whether the document is in fullscreen mode
      this.fullscreen = Boolean(document.fullscreenElement);
    },
  },
  // Define lifecycle hooks to automatically update the fullscreen state of the app
  created() {
    // Add an event listener to update the fullscreen state when the app is created
    this.updateFullScreenState();

    // Add an event listener to update the fullscreen state when the document enters or exits fullscreen mode
    document.addEventListener("fullscreenchange", this.updateFullScreenState);
  },
  destroyed() {
    // Remove the event listener for updating the fullscreen state when the app is destroyed
    document.removeEventListener("fullscreenchange", this.updateFullScreenState);
  }
};
</script>


