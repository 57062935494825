<template>
      <v-sheet >
        <slider/>
        <trends/>
        <newsletter/>
        <x/>
      </v-sheet>
</template>
<script>
import axios from 'axios';
import slider from './slider/Slider.vue';
import trends from './trends/Trends.vue';
import newsletter from './newsletter/Newsletter.vue';
import x from './x.vue';

export default {
  name: 'index',
  components: {
    slider,
    trends,
    newsletter,
    x,
  },
  
}
</script>