import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import useUserStore from './store/auth'
import VueSession from 'vue-session'
// Vue.use(VueSession)
// import stores from './stores'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import './assets/css/main.css'
// import 'vue-organization-chart/dist/style.css';
import axios from 'axios'

import i18n from './locales/i18n';
axios.defaults.baseURL = 'https://www.tigraypo.gov.et/portal/public/api/';

axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
// axios.defaults.headers['Content-type'] = 'application/json';
axios.defaults.headers['Content-type'] = 'multipart/form-data';

loadFonts()


const app = createApp(App)


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(useUserStore)
app.use(i18n);
app.use(router)
app.use(vuetify)

app.mount('#app')

export { i18n };