<template>
    <v-row justify="center" style="background-color: var(---secondary-color);" class="mt-5">
        <v-row justify="center" class="mx-15 my-6 pb-7">
          <v-col cols="12" md="12">
            <v-card            
            variant="outlined"
            class="my-border-color"
            >
              <v-card-text>
                <v-form @submit.prevent="subscribe" ref="form">
                  <v-row class="pt-8">
                    <v-col cols="12" md="7">
                      <p><h2>{{$t('OFFICEOFTHEPRESIDENTUPDATES')}}</h2></p><br>
                      <p>{{$t('subscribetoofficeofthepresident')}}</p>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="email"
                        clearable
                        dense
                        :label="$t('YourEmailrequired')"
                        placeholder="mail@example.com"
                        required
                        type="email"
                        :rules="emailRules"
                        density="compact"
                        prepend-inner-icon="mdi-email"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                       <v-btn type="submit" color="success" 
                        prepend-icon="mdi-bell"
                        :loading="loading"
                         class=""
                      >{{$t('subscribe')}}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="justify-center">
                    <v-col cols="12" md="6">
                      <v-alert    
                        v-if="sent"
                        type="success"              
                        :text="$t('successfullySubscribed')"
                      ></v-alert>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </v-row>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      email: '',
      emailRules: [
        value => !!value || 'Email is required',
        value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Invalid email format',
      ],
      loading: false,
      sent: false,
    };
  },
  methods: {
   async subscribe() {
      const { valid } = await this.$refs.form.validate()
        if (!valid) //alert('Form is valid');
          return;
        else {
        this.loading = true;
        let data = { email: this.email };
         await axios.post('/subscribers', data)
          .then(response => {
            this.loading = false;
            this.sent = true;
            this.reset();
            console.log(response.data)
            // this.clearApplication();
            // Set a timeout of 2000 milliseconds (2 seconds)
            setTimeout(() => {
              // After the timeout, set applySuccess to false
              this.sent = false;
            }, 5000);
          })
          .catch(function (error) {
            // console.log(error.response);
          });
      }
    },
    reset() {
        this.$refs.form.resetValidation();
        this.$refs.form.reset();
      },
  },
};
</script>