<template>
	<v-sheet style="background-color:#fffffe">
        <v-row class="justify-center  mb-5 mt-8">
          	<v-alert
							border="top"
							border-color="blue-darken-3"
							elevation=""
							>
				<v-row class=" pt-5">
					<v-col cols=12 sm=8 class="px-10">
		        <span class="text-h4 my-text-color" style="border-bottom:5px solid; #039be5;">{{$t('currentaffairs')}}</span>
					</v-col>
					<v-col cols=12 sm=8></v-col>
		    </v-row>
			</v-alert>
        </v-row>
		<v-row v-if="loading">
	      <v-col cols="12" md="4" sm="6"
	      v-for="i in 6" :key="i">
	        <v-avator>
	          <v-skeleton-loader
	            class="mx-auto border"
	            max-width="90%"
	            type="card-avatar"
	          ></v-skeleton-loader>
	        </v-avator>
	      </v-col>
	    </v-row>
		<v-row v-else class="ma-5 pb-4">
			<v-col cols="12" md="4" sm="6"
        v-for="post in paginatedPosts"
        :key="post"
      >
        <v-hover
          v-slot="{ isHovering, props }"
          open-delay="20"
        >
            <v-card
              :elevation="isHovering ? 16 : 1"
              :class="{ 'on-hover': isHovering }"
              class="ma-4 mx-auto "
              
              min-height="400"
              max-width="400"
              @click="toggle"
              v-bind="props"
            >
              <a :href="`/#/posts/${slug}/${post.id}`" style="text-decoration: none;">
              <v-img
                :src="`${$root.globalImgUrl}${post.image}`" 
                class="align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                max-height="200px"
              >
                <v-card-title class="my-backgroud-color bg-blue-lighten-1 text-white " bg="red">
                  {{trans(post.id, post, 'title')}}
                </v-card-title>
              </v-img>
              </a>
              <v-card-text>
                <v-icon size="17"> mdi-calendar </v-icon> {{ (post.created_at).split('T')[0] }}
                <div v-html="removeHtmlFormatting(trans(post.id, post, 'body'), 200)"></div>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn size="small" :to="`/posts/${slug}/${post.id}`" color="primary" variant="outlined" prepend-icon="mdi-more">{{$t('readmore')}}</v-btn>
              </v-card-actions>
            </v-card>
        </v-hover>
      </v-col>
		</v-row>
		<v-row v-if="!loading" class="justify-center mb-15">
			<!-- <v-pagination
			  v-model="currentPage"
			  :length="Math.ceil(postsResponse.length / itemsPerPage)"
			></v-pagination> -->
			<v-btn variant="outlined" class="mt-3" color="blue-lighten-1" to="/posts/all-news">{{$t('visitallnews')}}</v-btn>
		</v-row>

 <v-row class="justify-center mb-5">
          	<v-alert
							border="top"
							border-color="info"
							elevation=""
							>
				<v-row class=" pt-5">
					<v-col cols=12 sm=8 class="px-10">
		        <span class="text-h4 my-text-color" style="border-bottom:5px solid; #039be5;">{{$t('initiatives')}}</span>
					</v-col>
					<v-col cols=12 sm=8></v-col>
		    </v-row>
			</v-alert>
        </v-row>
		<v-row v-if="loading">
	      <v-col cols="12" md="4" sm="6"  
	      v-for="i in 6" :key="i">
	        <v-avator>
	          <v-skeleton-loader
	            class="mx-auto border"
	            max-width="90%"
	            type="card-avatar"
	          ></v-skeleton-loader>
	        </v-avator>
	      </v-col>
	    </v-row>
		<v-row v-else class="mx-5 mt-n15 pb-4 justify-center">
      <v-col cols="12" class="section-gap-top home_initiative" v-for="(initiative, index) in initiatives" :key="index">
        <v-row align="center">
          <v-col cols="12">
            <h1 class="section-title text-center my-text-color">{{ trans(initiative.id, initiative, 'title') }}</h1>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="initiative-card pa-6">
              <v-card-text v-html="trans(initiative.id, initiative, 'description')"></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-img :src="`${$root.globalImgUrl}/${initiative.image}`" class="img-fluid" max-height="280"></v-img>
          </v-col>
        </v-row>
        <div style="height: 40px;"></div>
      </v-col>


		</v-row>
	</v-sheet>
</template>

<script>
import axios from 'axios';
import { useUserStore } from "@/store/auth";

export default {
  name: 'updates',
  data: () => ({
    auth:useUserStore(),
    langSearch:"", 
    postsResponse: [],
    loading: true,
    originalText: '',
    currentPage: 1, // Current page number
  	itemsPerPage: 6,
    initiatives: []
  }),
  mounted () {          
      this.getPosts();
      this.getInitiatives();
    },
  computed: {
    sanitizedText() {
      return this.removeHtmlFormatting(this.originalText);
    },
    paginatedPosts() {
	    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
	    const endIndex = startIndex + this.itemsPerPage;
	    return this.postsResponse.slice(startIndex, endIndex);
  },
  },
  methods: {
    trans(id, str, col){
        if(this.auth.lang==="En")
          this.langSearch="EN"
        else if(this.auth.lang==="ትግ")
          this.langSearch="ti"
        else if(this.auth.lang==="ኣማ")
          this.langSearch="am"
      const translation = str.translations.find(translation => translation.locale === this.langSearch && translation.column_name === col);
      if(col == 'title')
        return translation ? translation.value : str.title;
      else if(col == 'body')
        return translation ? translation.value : str.body;
      else if(col == 'description')
        return translation ? translation.value : str.description;
    },
    removeHtmlFormatting(text, maxLength) {
      // Create a temporary div element
      const tempDiv = document.createElement('div');

      // Set the innerHTML of the div to the provided text
      tempDiv.innerHTML = text;

      // Retrieve the text content from the div (without HTML formatting)
      // return tempDiv.textContent.trim() || tempDiv.innerText.trim();

      if (text.length > maxLength) {
        // shortenText
        return (tempDiv.textContent.substring(0, maxLength) + '...') || (tempDiv.innerTextsubstring(0, maxLength) + '...');
      }
      return tempDiv.textContent.trim() || tempDiv.innerText.trim();
    },

    shortenText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },

    async getPosts(){
      await axios.get('/posts')
        .then(response => {
          this.postsResponse = response.data.mydata;
          this.loading = false;
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getInitiatives(){
      await axios.get('/initiatives')
        .then(response => {
          this.initiatives = response.data.mydata;
          this.loading = false;
        })
        .catch(error => {
          console.log(error)
        });
    },

  }
}
</script>
<style>
.section-gap-top {
  margin-top: 40px;
}
.initiative-card {
  transition: transform 0.3s ease;
}
.initiative-card:hover {
  transform: translateY(-5px);
}
</style>