<script>
import { defineComponent } from 'vue';
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'UserShow',
  components: {
    QrcodeVue,
  },
  
  data: () => ({
      valid: true,
      alert: false,
      loading: true,
      message: '',

      value: 'https://example.com',
      size: 150,

      user: {
        id: '',
        name: '',
        email: '',
        created_at: '',
        roles: [
          {        
            id: '',    
            name: '',
            guard_name: '',
            permissions: [
              {        
                id: '',    
                name: '',
                guard_name: '',
              },
            ],
          },
        ],
      },

      roles: [],
      permissions: [],
      qrcode: '',
      
      
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      checkbox: false,
    }),

    computed: {
      currentRouteName(){
        return this.$route.name;
      }
    },
    mounted () {
      var userId = this.$route.params.id;
      this.getUser(userId);
      
    },

    methods: {
      async getUser(userId){
        await axios
        .get('/users/'+userId)
        .then(response => {
          // this.invoice = response.data.invoice;
          
          if (response.data.message == "error") {
            this.message = "Payment not found";
          } else {
            this.user = response.data.user;
            this.qrcode = response.data.qrcode;
            // console.log(response.data.user.roles.permissions);
            console.log(response.data.qrcode);
            // this.user.id = response.data.user.id;
            // this.user.name = response.data.user.name;
            // this.user.email = response.data.user.email;
            // this.user.created_at = response.data.user.created_at;
            // this.user.roles = response.data.user.roles;

            // items = [];
            response.data.user.roles.forEach((role) => {
              this.roles.push(role.name);
              role.permissions.forEach((permission) => {
                this.permissions.push(permission.name);
              })
            })
            // this.user.roles = items

            this.loading = false;
          }
            // console.log(response.data.message);
            // console.log(response.data.user);
            // console.log(response.data);
        })
        .catch(error => {
          console.log(error)
        });
      },
       
      submit(){
          let data = { memberTypeID: this.memberType.id,  payInterval: this.payInterval.name, duration: this.duration };
          console.log(data);
          axios.post('/invoices', data)
          .then(function (response) {
            console.log(response);
            this.alert = true;
          })
          .catch(function (error) {
            console.log(error);
          });
        },
      async validate () {
        const { valid } = await this.$refs.form.validate()

        if (valid) {
          //alert('Form is valid')
          this.submit();
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
};
</script>

<template>
  
  <v-card  class="mx-auto" color="grey-lighten-3">
    <v-layout>

      <v-main>
        <v-container fluid>
            <div class="dashboard">
              
              <v-row prepend-icon="mdi-account-group-outline">
                  <v-col cols="10"><h4></h4></v-col>
                  <v-col cols="2" >
                    <span style="float:right; padding-right: 10px;">
                      
                    </span>                  
                  </v-col>
              </v-row>
            </div>
            <v-card prepend-icon="mdi-account-group-outline">
              <template v-slot:title>
              <v-row >
                <v-col cols="10">User Detail </v-col>
                <v-col cols="2" class="">
                  <v-btn
                    color="info"
                    class="mt-4 pull-right"
                    size="small"
                    prepend-icon="mdi-arrow-left"
                    to="/users"
                    >
                    List
                  </v-btn>
                </v-col>
              </v-row >
            </template>
            <v-row>
            <v-container v-if="loading">
              <div class="text-xs-center">
                  <v-progress-circular
                    indeterminate
                    :size="50"
                    :width="8"
                    color="green">
                  </v-progress-circular>
                </div>
              </v-container>
              <v-col cols="12" v-else>
                  
                <v-form v-model="valid" ref="form">
                    <v-container>
                      <v-row v-if="message != ''">
                        <v-col cols="12" md="3">
                          <b>Message:</b> {{message}}
                        </v-col>
                      </v-row>
                      <v-row v-else >
                        <v-col cols="12" md="6" id="printable-content">
                          <v-row> 
                          <v-col cols="12" md="12">
                            <h3>
                            <v-avatar>
                              <v-img
                                :src="require('@/assets/tigray-logo.png')"
                                size="100px"
                                alt="John"
                              ></v-img>
                            </v-avatar> &nbsp; 
                            
                            {{user.name}} &nbsp;   <small>[ {{user.email}} ]</small></h3> <hr color="#dcdcdc">
                            <span style="float:;">Registered On: <small>{{user.created_at.split('T')[0]}}</small></span>
                          </v-col>

                          <v-col cols="12" md="6">
                            <b>Roles:</b> 
                            <!-- {{roles}} -->
                            <br>
                              <v-checkbox   v-for="(role, index) in user.roles"
                              :key="index"                           
                              v-model="roles"
                              :label="`${role.name}`"
                              :value="`${role.name}`"
                            ></v-checkbox>   
                          </v-col>
                          <v-col cols="12" md="12"> 
                           <b>Permissions:</b> 
                            <!-- {{permissions}} -->
                           <br>
                           <v-col cols="12" md="4" v-for="(myrole, index) in user.roles"
                              :key="index">
                              {{myrole.name}} <hr>
                             <v-checkbox   v-for="(permission, index2) in myrole.permissions"
                              :key="index2"                           
                              v-model="permissions"
                              :label="`${permission.name}`"
                              :value="`${permission.name}`"
                            ></v-checkbox> 
                           </v-col>
                              
                          </v-col> 
                          </v-row> 
                        </v-col> 

                        <v-col cols="12" md="4">
                          <v-text-field
                              v-model="user.email"
                              label="QR Code"
                              type="text"
                              required
                          ></v-text-field>
                           
                            <figure class="qrcode">                             
                            <qrcode-vue :value="user.email" :size="size" render-as="svg" background="#9ad13b" level="H" />
                            <!--  foreground="#4bd6c1" -->
                            <v-avatar class="qrcode__image">
                              <v-img
                                :src="require('@/assets/tigray-logo.png')"
                                size="100px"
                                alt="John"
                              ></v-img>
                            </v-avatar>
                          </figure>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-btn 
                                color="success"
                                class="mt-4"
                                @click="printContent"
                                prepend-icon="mdi-printer"
                            >
                            Print
                            </v-btn> &nbsp; 
                            <p>Number of pages printed: {{ numPagesPrinted }}</p>
                        </v-col>
                    </v-row>

                    </v-container>
                </v-form>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-alert 
                              v-if="alert"
                              v-model="saved"
                              border="start"
                              variant="tonal"
                              closable
                              close-label="Close Alert"
                              color="success"
                              title="Message"
                            >
                            <!-- color="deep-purple-accent-4" -->
                              Invoice created successfully.
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
                  
              </v-col>
            </v-row>
          </v-card>
        </v-container>   
      </v-main>
    </v-layout>
  </v-card>
</template>
<style type="text/css">
  .qrcode {
    display: inline-block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
  }

  .qrcode__image {
    background-color: #fff;
    border: 0.15rem solid #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
    height: 15%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15%;
  }
</style>